import { useStableProps } from '@/hooks/useStable';
import StandaloneVideoSlider from 'base/components/VideoSlider';

export const ElleStandaloneVideoSlider: typeof StandaloneVideoSlider = (props) => {
  const stableProps = useStableProps(
    {
      options: {
        $swiper: { $arrow: { className: 'text-gray-300' } },
        $header: { $arrow: { name: 'sliderArrow', className: 'rotate-180 w-2' } },
      },
    },
    props,
  );

  return <StandaloneVideoSlider {...stableProps} />;
};

export default ElleStandaloneVideoSlider;
